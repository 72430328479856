
















import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { generalDataService } from '@/services/GeneralDataService';

@Component({
  components: { Header },
})
export default class Report extends Vue {
  companyUnits: any = [];
  categories: any = [];
  reportId = '';
  mounted() {
    this.reportId = this.$route.params.id || '0';
    generalDataService
      .list('generalList/structureUnit')
      .then((data: any) => {
        this.companyUnits = data;
      })
      .catch(() => {});
    generalDataService
      .list(`generalSubList/reportField/${this.reportId}`)
      .then((data: any) => {
        this.categories = data;
      })
      .catch(() => {});
  }
}
